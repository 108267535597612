import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { checkAuthenticationStatus, setAuthChecked } from '../../store/userSlice';
import { signOut } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import './PendingWriterComponent.css';


const PendingWriterComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCheckStatus = () => {
    // No checks, just go home
    navigate('/');
  };
  

  const handleSignOut = async () => {
    try {
        await signOut();
        dispatch(setAuthChecked(false));
        dispatch(checkAuthenticationStatus());
    } catch (error) {
        console.error("Error signing out: ", error);
    }
  };

  return (
    <div className="pendingWriterComponent">
      <div className="messageBox">
        <h2 className="bold"> Application pending</h2>
        <p>Your application is currently under review. We will get back to you shortly.</p>
        <p>Feel free to email shorterstories@shorterstories.xyz if you want to get in touch. Maybe you'd like to ask a question, rant about the state of the world or maybe you just want to tell us we are a bunch of jerks. Whatever you're feeling.</p>
      </div>
      <button onClick={handleCheckStatus} className="checkStatusButton">Return to Home Screen</button>
      <div className="separator"></div>
      <button onClick={handleSignOut} className="signOutButton">Sign Out</button>
    </div>
  );
};

export default PendingWriterComponent;
