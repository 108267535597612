import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomeForReaders.css';
import BookWorld from '../../assets/images/BookWorld.png';
import WriterReading from '../../assets/images/writer-falling.png';


function HomeForReaders() {
  const navigate = useNavigate();
  

  const handleInfo = () => {
    navigate('/readers');
  };

  const handleInfoWriters = () => {
    navigate('/writers');
  };

  return (
    <div className="home-readers-container">

      {/* Hero or Header Section */}
      <div className="heroed-section">
        
        <h1 className="hero-heading" style={{ fontSize: "20px" }}>Step into the Story...</h1>
        <p className="hero-subtext">
          As a reader on ShorterStories, you'll uncover a diverse world of stories, books and author connections. 
        </p>
        <button className="cta-button" onClick={handleInfo}>
          Enter the reader’s site
        </button>
        <img src={BookWorld} alt="BookWorld" className="hero-image" />
      </div>

      <hr className="subtlest-line-aboutwords" />

      {/* Next Section: Writer Application Teaser */}
      <div className="heros-sections">
        <h1 className="heros-headings" style={{ fontSize: "20px" }}>Fancy yourself a writer?</h1>
        

        <p>
            Explore the perks of sharing your own stories, building an audience and connecting with fellow creatives.
        </p>
        <button className="cta-button" onClick={handleInfoWriters}>
          Apply to become a writer on ShorterStories
        </button>
        <img src={WriterReading} alt="WriterReading" className="hero-image" />
        <p 
          className="info-link-homeforreaders" 
          onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' });
          navigate('/about/writers');}}
        >
          Find out more about what it means to be a writer on ShorterStories
        </p>

        
        {/* Possibly another button leading to the writer application or more info. */}
      </div>

    </div>
  );
}

export default HomeForReaders;
