import React, { useState } from "react";
import { useDispatch, useSelector} from "react-redux";
import { Link } from "react-router-dom";
import { apiInstance } from "../../utils/axiosInstance";
import { showApplicationReviewModal } from "../../store/modalSlice"; 

import { signOut } from 'aws-amplify/auth';
import { fetchAuthSession } from 'aws-amplify/auth';

import './ReviewApplications.css';
import { checkEmployeeStatus } from '../../store/employeeSlice';
import { setApplications } from "../../store/applicationsSlice";
import { setAuthChecked } from '../../store/userSlice';




const ReviewApplications = () => {

  const [selectedApplication, setSelectedApplication] = useState(null);
  const [fetchStatus, setFetchStatus] = useState(null);
  const applications = useSelector((state) => state.applications.applications);
  const [alertMessage, setAlertMessage] = useState("");
  const dispatch = useDispatch();

  const fetchApplications = async () => {
    setFetchStatus('loading');
    setAlertMessage("");
    try {
        const session = await fetchAuthSession();
        const jwtToken = session.tokens.idToken.toString();
        const response = await apiInstance.get("/applications/employeeReview", {
            headers: { Authorization: `Bearer ${jwtToken}` },
        });
        dispatch(setApplications(response.data.applications));
        setFetchStatus('success');
        setAlertMessage("Applications list updated successfully!");
    } catch (error) {
        console.error("Error fetching applications:", error);
        setFetchStatus('error');
        setAlertMessage("Failed to update applications list. Please try again.");
    }
};

    const handleRowClick = (application) => {

        setSelectedApplication(application);
      };
    
      const handleReviewClick = () => {
        dispatch(showApplicationReviewModal({
          modalType: "applicationReviewModal",
          modalProps: {
            application: selectedApplication
          }
        }));
      };

    const handleSignOut = async () => {
        try {
            await signOut();
            dispatch(setAuthChecked(false));
            dispatch(checkEmployeeStatus());
            // Add your action to handle sign out if needed
        } catch (error) {
            console.error("Error signing out: ", error);
        }
      };

    const getSocialOrMagsCount = (application) => {

        const socialCount = application.socialMedia ? application.socialMedia.length : 0;
        const magCount = application.magPublished ? application.magPublished.length : 0;
      
        // Sum the counts, ensuring the sum does not exceed 2
        let total = socialCount + magCount;
        return total;
      };
    
    const getPublishedStatus = (application) => {
        const publishedLower = application.published.toLowerCase();
        if (publishedLower === 'yes') {
          return application.authorId ? "Yes with ID" : "Yes no ID";
        } else if (publishedLower === 'no') {
          return "No";
        }
        return "Unknown"; // Fallback for any unexpected value
      };
      

      return (
        <div>
          <button 
              className="fetch-button" 
              onClick={fetchApplications}
              disabled={fetchStatus === 'loading'}
          >
              {fetchStatus === 'loading' ? 'Fetching...' : 'Get updated list of open applications'}
          </button>
          <Link to="/employee-dashboard" className="home-link">Back to Dashboard</Link>
          
          {alertMessage && (
              <div className={`alert ${fetchStatus === 'success' ? 'alert-success' : 'alert-error'}`}>
                  {alertMessage}
              </div>
          )}

          <button className="logout-button" onClick={handleSignOut}>Log Out</button>
          <div className="table-container">
            <table>
                <thead>
                <tr>
                    <th>Created At</th>
                    <th>Writer's Name</th>
                    <th>Published</th>
                    <th>Examples</th>
                    {/* Add other columns here */}
                </tr>
                </thead>
                <tbody>
                {applications.map((application) => (
                    <tr
                    key={application.applicationId}
                    onClick={() => handleRowClick(application)}
                    className={selectedApplication?.applicationId === application.applicationId ? "selected" : ""}
                    >
                    <td>{application.createdAt?.split('T')[0]}</td>
                    <td>{application.writersName}</td>
                    <td>{getPublishedStatus(application)}</td>
                    <td>{getSocialOrMagsCount(application)}</td>
                    {/* Add other data here */}
                    </tr>
                ))}
                </tbody>
            </table>
          </div>
          <button className="review-button" disabled={!selectedApplication} onClick={handleReviewClick}>
            Review Selected Application
          </button>
        </div>
      );
    };
    
    export default ReviewApplications;