import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentUser, fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';
import { signOut } from 'aws-amplify/auth';
import { setEmployeeRole } from './employeeSlice';
import {Amplify} from 'aws-amplify';
import awsconfig from '../aws-config'; 
import { apiInstance } from "../utils/axiosInstance"; // ✅ Import axios instance



Amplify.configure(awsconfig);

const initialState = {
  userId: null,
  authChecked: false,
  isAuthenticated: false,
  userRole: null,
  userName: null, 
  userEmail: null,
  passPassword: null,
  passEmail: null,
  currentLoginPage: 'login',
  currentForgotPasswordPage: 'forgotPassword',
  userGeoInfo: null,

};

export const fetchUserGeoInfo = createAsyncThunk("user/fetchUserGeoInfo", async (_, { getState }) => {
  const { userGeoInfo } = getState().user;
  if (userGeoInfo?.country_code) return null; 

  try {
    console.log("🌎 Fetching geo info...");
    const response = await apiInstance.get("/system/geo-info");
    console.log("✅ API Response:", response.data); 
    return response.data;
  } catch (error) {
    console.error("❌ Geo info fetch error:", error);
    return null;
  }
});


// Async thunk action
export const checkAuthenticationStatus = createAsyncThunk(
  'user/checkAuthenticationStatus',
  async (_, { dispatch, getState }) => { 
    try {
      const { authChecked } = getState().user;
      if (authChecked) return;
      console.log('Step 1: Getting current user');

      const { username } = await getCurrentUser();

      const currentUser = await getCurrentUser();
      console.log('Current user:', currentUser);

      console.log('Step 2: Fetching auth session');
      const { tokens } = await fetchAuthSession();
      console.log('Auth session fetched');

      let userAttributes;
      try {
        console.log('Step 3: Fetching user attributes');
        userAttributes = await fetchUserAttributes();
        console.log('User attributes:', userAttributes);
      } catch (attrError) {
        console.warn('Could not fetch user attributes:', attrError);
        userAttributes = {}; // Use an empty object if attributes can't be fetched
      }
      
      const userRole = userAttributes['custom:userRole'];
      const employeeRole = userAttributes['custom:employeeRole'];
      const usersName = userAttributes.name;
      console.log('UserId:', currentUser.userId);
      const userId = currentUser.userId;

      dispatch(setUserId(userId));
      dispatch(setIsAuthenticated(true));
      dispatch(setEmployeeRole(employeeRole));
      dispatch(setUserRole(userRole));
      dispatch(setUserEmail(userAttributes.email));
      dispatch(setUserName(usersName));
      dispatch(setAuthChecked(true));

    } catch (error) {

      console.error('Error details:', error.name, error.message, error.stack);
      dispatch(setIsAuthenticated(false));
      dispatch(setUserRole(null));
      dispatch(setUserEmail(null));
      dispatch(setUserName(null));
      dispatch(setUserId(null));
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setAuthChecked: (state, action) => {
      state.authChecked = action.payload;
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setPassPassword: (state, action) => {
      state.passPassword = action.payload;
    },
    setPassEmail: (state, action) => {
      state.passEmail = action.payload;
    },
    setCurrentLoginPage: (state, action) => {
      state.currentLoginPage = action.payload;
    },
    setCurrentForgotPasswordPage: (state, action) => {
      state.currentForgotPasswordPage = action.payload;
    },  
    setUserGeoInfo: (state, action) => {
      console.log("📥 Redux setUserGeoInfo:", action.payload); // ✅ Log updates
      state.userGeoInfo = action.payload;
    },
    setGeoInfoLoading: (state, action) => {
      console.log("⏳ Redux setGeoInfoLoading:", action.payload); // ✅ Log loading state
      state.geoInfoLoading = action.payload;
    } 
  },

  extraReducers: (builder) => {
    builder.addCase(fetchUserGeoInfo.fulfilled, (state, action) => {
      if (action.payload) state.userGeoInfo = action.payload;
    });
  }
});

export const { setCurrentForgotPasswordPage, setPassEmail, setPassPassword, setCurrentLoginPage, setUserName, setUserId, setIsAuthenticated, setUserRole, setUserEmail, setAuthChecked, setUserGeoInfo, setGeoInfoLoading } = userSlice.actions;
export default userSlice.reducer;
