import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { apiInstance } from '../../utils/axiosInstance';
import Header from '../../components/Header/Header';
import ProfileFeed from '../../components/ProfileFeed/ProfileFeed';

import AuthorSummary from '../../components/AuthorSummary/AuthorSummary';
import './ProfilePage.css';
import { checkAuthenticationStatus } from '../../store/userSlice';



const ProfilePage = ({ authorInfo }) => {
    const dispatch = useDispatch();
    const { profileHash } = useParams(); 
    const [authorProfile, setAuthorProfile] = useState(authorInfo || null); 
    const [pieces, setPieces] = useState([]);
    const [topBooks, setTopBooks] = useState([]);
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);
    const userId = useSelector(state => state.user.userId);
    const [isAuthChecked, setIsAuthChecked] = useState(false); 


    useEffect(() => {
      const checkAuth = async () => {
          await dispatch(checkAuthenticationStatus());
          setIsAuthChecked(true); // ✅ Mark auth check as complete
      };

      checkAuth();
  }, [dispatch]);

    useEffect(() => {
      if (!isAuthChecked) return; 

    
      const fetchAuthorProfile = async () => {
        try {
          // Include the profile_hash in the request URL
          const url = `/platformAuthors/summary/fetch?profile_hash=${profileHash}`;
          const response = await apiInstance.get(url);
          // Directly access `response.data` since the API returns the serialized data at the top level
          setAuthorProfile(response.data);
        } catch (error) {
          console.error('Error fetching the author profile:', error);
        }
      };
    
      const fetchProfileFeed = async () => {
        try {
          // Include the profile_hash in the request URL for the profile feed as well
          const feedUrl = `/pieces/profilefeed/fetch/v2?profile_hash=${profileHash}${isAuthChecked && isAuthenticated ? `&consumer_id=${userId}` : ''}`;

          const response = await apiInstance.get(feedUrl);
          // Assuming `response.data` contains the feed data
          setPieces(response.data.feed_data); // Adjust according to your API response structure

        } catch (error) {
          console.error('Error fetching the profile feed:', error);
        }
      };

      const fetchTopBooks = async () => {
        try {
            const response = await apiInstance.get(`/links/top_books_profile?profile_hash=${profileHash}`);
            setTopBooks(response.data.top_books);
            console.log('Top books:', response.data.top_books);
        } catch (error) {
            console.error('Failed to fetch top books:', error.response?.data);
        }
    };
    
      // Call the fetch functions
      fetchAuthorProfile();
      fetchProfileFeed();
      fetchTopBooks();



    }, [isAuthChecked, profileHash]); // This will run once on component mount


    return (
    <div className="profilePage">
        <Header />
        {authorProfile && <AuthorSummary summary={authorProfile} topBooks={topBooks} />}
        
        {authorProfile && pieces.length > 0 && (
            <ProfileFeed pieces={pieces} authorProfile={authorProfile} />
        )}
    </div>
    );
};
    
    export default ProfilePage;