// Import necessary components and hooks from react-router-dom
import React, { useState, useEffect }  from 'react';
import { Routes, Route, useNavigate, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../components/HeaderPublishPreview/HeaderPublishPreview';
import BooksInPiece from '../../components/BooksInPiecePreview/BooksInPiecePreview';
import { fetchAuthorProfile } from '../../store/authorProfileSlice';
import defaultImage from '../../assets/images/birdie.jpeg';
import './WritersPublishPreview.css';
import printingPressProfile from '../../assets/images/printingpressprofile.png';

import { useMutation, useQueryClient } from 'react-query';

import { fetchAuthSession } from 'aws-amplify/auth';
import { apiInstance } from '../../utils/axiosInstance';
import {resetPublishingProcess} from '../../store/publishingSlice';
import {clearSelections} from '../../store/taggingSlice';


function PublishPreview() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    title,
    piece,
    taggedBooks,
    description,

    genre,
    style,
    isSerial,
    serialFirstProfileHash,
    serialPartNumber,
    previousSerialHash

  } = useSelector(state => state.publishing);

  const queryClient = useQueryClient();


  const [isPublishing, setIsPublishing] = useState(false);

  function stripHtml(html) {
    // Create a new div element
    const temporalDivElement = document.createElement("div");
    // Set the HTML content with the provided
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  }

  function countWords(text) {
    // Use regular expression to replace multiple spaces with a single space and trim spaces
    return text.replace(/\s+/g, ' ').trim().split(' ').length;
  }

  function countWords(text) {
    // Use regular expression to replace multiple spaces with a single space and trim spaces
    return text.replace(/\s+/g, ' ').trim().split(' ').length;
  }

  const wordCount = countWords(stripHtml(piece));

  const { mutate: publishPiece } = useMutation(
    async (formData) => {
      const session = await fetchAuthSession();
      const jwtToken = session.tokens.idToken.toString();

      console.log('formData', formData);

      const response = await apiInstance.post('/pieces/create_two', formData, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data;
    },
    {
      onSuccess: (data) => {
        console.log('Piece published successfully:', data);
        queryClient.invalidateQueries(['pieces']);
        queryClient.refetchQueries(['pieces']);
        queryClient.invalidateQueries(['profileFeed', userId, userName]);
        queryClient.refetchQueries(['profileFeed', userId, userName]); 
        dispatch(clearSelections());
        dispatch(resetPublishingProcess());
        navigate('/writers');
        setIsPublishing(false);
      },
      onError: (error) => {
        console.error('Error publishing piece:', error);
        alert('Failed to publish the piece. Please try again.');
        setIsPublishing(false);
      },
    }
  );
  

  const getLengthCategory = (text) => {
    const wordCount = countWords(stripHtml(text));
    if (wordCount <= 250) return 'Bite Sized';
    if (wordCount <= 1500) return 'Flash';
    if (wordCount <= 5000) return 'Shorter Story';
    return 'Short Story';
  };


  const { userId, userName } = useSelector(state => state.user);


  const currentPhoto = useSelector(state => state.publishing.currentPhoto) || defaultImage;
  const { data: authorProfile, isLoading: isLoadingProfile, error: errorProfile } = useSelector((state) => state.authorProfile);


  // Fetch author profile data
  useEffect(() => {
      if (!authorProfile && !isLoadingProfile) {
        // Trigger the fetch only if authorProfile hasn't been loaded and not currently loading
        dispatch(fetchAuthorProfile({ userId, userName }));
      }
      // It's essential to include isLoading in the dependency array if its value is used in the condition
    }, [dispatch, userId, userName, authorProfile, isLoadingProfile]);
  // Use useNavigate for navigation actions
  

  
  if (isLoadingProfile || !authorProfile) {
    return <div>Loading author information...</div>;
  }

  const handleImageLoad = (e) => {


    const img = e.target;
    if (img.naturalWidth * 1.0< img.naturalHeight) {

        // Adjust the image height to match its width for landscape images
        img.style.width = '100%'; // Set the width to 40% of its container
        img.style.height = `${img.offsetWidth * 1}px`;  // Match the height to the current width
        img.style.objectFit = 'cover'; // Ensure the image covers the area, cropping as necessary
    } else {

        // Apply or reset styles for portrait/square images as necessary
        img.style.width = '100%'; // Maintain the 40% width
        img.style.height = 'auto'; // Allow the height to adjust naturally
    }
};

  const navigateToProfile = () => {
    navigate('/writers/publish/details'); // Assuming '/profile' is the route for the profile page
  };


  const saveChanges = async () => {
    try {
      setIsPublishing(true);
  
      // Prepare the payload
      const payload = {
        title,
        piece_content: piece,
        piece_genre: genre,
        piece_style: style,
        taggedBooks,
        userId,
        profile_hash: authorProfile.profile_hash,
        author_name: authorProfile.name,
        publishing_user: userId,
        description,
        piece_length: wordCount,
        show_in_the_feed: authorProfile.profile_hash !== 'AcS1Na49',
        is_serial: isSerial,
        serial_first_profile_hash: serialFirstProfileHash,
        serial_part_number: serialPartNumber,
        previous_serial_hash: previousSerialHash,
        image_key: currentPhoto !== defaultImage ? currentPhoto : null, // Pass the S3 key or null
      };
  
      console.log('Payload:', payload);
  
      // Call the mutation
      await publishPiece(payload);
    } catch (error) {
      console.error('Error in saveChanges:', error);
      alert(`Error saving piece: ${error.message || error}`);
      setIsPublishing(false);
    }
  };

  return (
    <div className="preview-piece-page">
      <Header />
      <div className="preview-contents-container">
        <div className="previews-author-info">
          <div className='preview-author-only'>
            <img src={authorProfile.profile_image_jpeg||printingPressProfile} alt="Author" className="preview-author-photo" />
            <span className="preview-author-name">{authorProfile.name}</span>
          </div>
          <span className="preview-length-indicator">{getLengthCategory(piece)}</span>
        </div>

        {currentPhoto && (
          <div className="preview-piece-image-container">
            <img src={currentPhoto} alt="Cover Art" className="preview-piece-image" onLoad={handleImageLoad}/>
          </div>
        )}
        <div className="preview-genre-style-container">
          <span>
            Genre: <span className="preview-italic-text">{genre !== 'blank' ? genre : 'Not specified'}</span>
          </span>
          <span>
            Style: <span className="preview-italic-text">{style !== 'blank' ? style : 'Not specified'}</span>
          </span>
        </div>
        <div className="preview-divider"></div>
        {taggedBooks && taggedBooks.length > 0 && (
          <>
            <div className="preview-promoted-books-text">Promoted books</div>
            <BooksInPiece feedItems={taggedBooks} />
            <div className="preview-divider"></div>
          </>
        )}
        <h3 className="preview-pieces-title">
          {title} <span className="preview-authors-name">by {authorProfile.name}</span>
        </h3>
        <div className="preview-piece-content" dangerouslySetInnerHTML={{ __html: piece }}></div>


        <div className="preview-divider"></div>
        <button className="buttonPreviewPiece" style={{ fontWeight: 'bold' }} onClick={!isPublishing ? saveChanges : undefined} disabled={isPublishing}>
          {isPublishing ? 'Publishing...' : 'Publish piece'}
        </button>

        <button className="buttonPreviewPiece" onClick={navigateToProfile} >
          Return to edit
        </button>



      </div>
    </div>

  );
}

export default PublishPreview;
