import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomeForApplicants.css';
import BookWorld from '../../assets/images/BookWorld.png';
import WriterReading from '../../assets/images/writer-falling.png';
import { useSelector, useDispatch } from 'react-redux';
import { checkAuthenticationStatus } from '../../store/userSlice';
import { fetchAuthSession } from 'aws-amplify/auth';
import {apiInstance} from '../../utils/axiosInstance';


function HomeForReaders() {
  const navigate = useNavigate();

  const handleInfo = () => {
    navigate('/readers');
  };

  const handleInfoWriters = () => {
    navigate('/writers');
  };

  const dispatch = useDispatch();
  const userId = useSelector(state => state.user.userId);

  const handleCheckStatus = async () => {
    try {
      const currentTime = new Date().getTime();
      const applicationLastCheckTime = Number(localStorage.getItem('applicationLastCheckTime')) || 0;

      if (applicationLastCheckTime && currentTime - applicationLastCheckTime < 30 * 1000) {
        window.alert("Whoa, hold your horses, our people don't work that hard, check back in a couple of hours.");
        return;
      }

      const session = await fetchAuthSession();
      const jwtToken = session.tokens.idToken.toString();

      const response = await apiInstance.get('/applications/status', {
        params: {
          userId: userId, // Now passing userId in the request
        },
        headers: { 'Authorization': `Bearer ${jwtToken}` },
      });

      if (response.data.status === 'accepted') {
        window.alert("You're in. Go get-a-writing.");
        dispatch(checkAuthenticationStatus()); // Trigger a re-check of authentication status to update role
      } else if (response.data.status === 'applied') {
        window.alert("Nothing has changed, application still pending. The people in the applications department are being lazy again.");
      } else {
        window.alert("Sorry dude or dudette. We denied the application. You see, we're going for a dress code. Show off your chaps somewhere and apply again in a month.");
        window.location.reload();
      }

      localStorage.setItem('applicationLastCheckTime', currentTime.toString());
    } catch (error) {
      console.error('Error checking application status:', error);
      window.alert("Oh no, something on our backend went wrong. Email turbo@shorterstories.xyz if you're worried about anything.");
    }
  };
  

  return (
    <div className="home-readers-container">

      {/* Hero or Header Section */}
      <div className="heroed-section">
        
        <h1 className="hero-heading" style={{ fontSize: "20px" }}>Step into the Story...</h1>
        <p className="hero-subtext">
            As a reader on ShorterStories, you’ll uncover a diverse world of stories, books and interactions with your favorite authors. 
        </p>
        <button className="cta-button" onClick={handleInfo}>
          Enter the reader’s site
        </button>
        <img src={BookWorld} alt="BookWorld" className="hero-image" />
      </div>

      <hr className="subtlest-line-aboutwords" />

      {/* Next Section: Writer Application Teaser */}
      <div className="heros-sections">
        <h1 className="heros-headings" style={{ fontSize: "20px" }}>Your application is currently under review</h1>
        

        <p>
          We will get back to you shortly. If you want to get in touch, feel free to email shorterstories@shorterstories.xyz. 
        </p>

        <img src={WriterReading} alt="WriterReading" className="hero-image" />

        
        {/* Possibly another button leading to the writer application or more info. */}
      </div>

    </div>
  );
}

export default HomeForReaders;



