import React from 'react';
import './AuthorSummary.css'; // Ensure you've imported the CSS file correctly
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { showGenericModal } from '../../store/modalSlice';
import defaultVerticalImage from '../../assets/images/defaultVerticalmage.png';
import RecentBooksProfile from '../../components/RecentBooksProfile/RecentBooksProfile';

const AuthorSummary = ({ summary, topBooks }) => {
    const [supportsWebp, setSupportsWebp] = useState(false);

    const dispatch = useDispatch();
    
    const determineGeneration = (yearOfBirth) => {
        if (yearOfBirth === null) {
            return null;
        }
        const lastDigit = yearOfBirth % 10;
        const decade = Math.floor(yearOfBirth / 10) * 10;
        
        if (lastDigit === 9) {
            return `${decade + 10}s and ${decade + 20}s`;
        } else if (lastDigit === 0 || lastDigit === 1) {
            return `${decade}s and ${decade + 10}s`;
        } else {
            return `${decade + 10}s`;
        }
    };

    // Check if in_bio_visible exists or use a default object with all false values
    const visibility = summary.in_bio_visible || {
        year_of_birth: true,
        major_genre: true,
        country: true,
        region: true,
        home_country: true,
        home_region: true,
    };

    function supportersWebp() {
        const elem = document.createElement('canvas');
        if (!!(elem.getContext && elem.getContext('2d'))) {
          // was able or not to get WebP representation
          return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
        }
        // very old browser like IE 8, canvas not supported
        return false;
      }
    
      useEffect(() => {
        setSupportsWebp(supportersWebp());
      }, []);

      
    
    const formatLocation = (isCountryVisible, isRegionVisible, regionName, countryName) => {
        // Use optional chaining to safely access .label
        const formattedRegionName = regionName?.label;
        const formattedCountryName = countryName?.label;
        
        // Conditions based on visibility and null checks
        if (isCountryVisible && !isRegionVisible && formattedCountryName) {
            return formattedCountryName; // Only country is visible and not null
        } else if (!isCountryVisible && isRegionVisible && formattedRegionName) {
            return formattedRegionName; // Only region is visible and not null
        } else if (isCountryVisible && isRegionVisible && formattedRegionName && formattedCountryName) {
            return `${formattedRegionName}, ${formattedCountryName}`; // Both are visible and not null
        }
        return ''; // Fallback for other cases
    };



      const handlePress = (modalType) => {
        dispatch(showGenericModal({
          modalType: modalType,
          modalProps: {
            links: summary.magazine_links,
            socials: summary.social_links,
          }
        }));
      };
    
    const generation = determineGeneration(summary.year_of_birth);
    const currentLocation = formatLocation(visibility.country, visibility.region, summary.region_name, summary.country_name);
    const homeLocation = formatLocation(visibility.home_country, visibility.home_region, summary.home_region_name, summary.home_country_name);


    const handleImageLoad = (e) => {


        const img = e.target;
        if (img.naturalWidth > img.naturalHeight) {
    
            // Adjust the image height to match its width for landscape images
            img.style.width = '100%'; // Set the width to 40% of its container
            img.style.height = `${img.offsetWidth}px`; // Match the height to the current width
            img.style.objectFit = 'cover'; // Ensure the image covers the area, cropping as necessary
        } else {
    
            // Apply or reset styles for portrait/square images as necessary
            img.style.width = '100%'; // Maintain the 40% width
            img.style.height = 'auto'; // Allow the height to adjust naturally
        }
    };

    const hasInfo = generation || summary.major_genre || currentLocation || homeLocation;
 
    
    return (
      <div className="authorSummary-wrapper">
        <div className="authorSummary">
            <div className="topCard-summary">
                <div className="imageContainer-summary">
                    <picture>
                        {supportsWebp && summary.vertical_image_webp ? (
                            <img src={summary.vertical_image_webp} alt="Piece" className="piece-image" onLoad={handleImageLoad}/>
                        ) : summary.vertical_image_jpeg ? (
                            <img src={summary.vertical_image_jpeg} alt="Piece" className="piece-image" onLoad={handleImageLoad}/>
                        ) : (
                            defaultVerticalImage && <img src={defaultVerticalImage} alt="Piece" className="piece-image" onLoad={handleImageLoad}/>
                        )}
                    </picture>

                </div>
                <div className="authorSummary-info">
                    <div className="authorName-container">
                      <h3 className="authorSummary-name">{summary.name}</h3>
                    </div>
                    {hasInfo ? (
                    <>
                        {generation && (
                        <p className="summary-info"><span className="info-label">Grew up in the:</span> {generation}</p>
                        )}
                        {summary.major_genre && (
                        <p className="summary-info"><span className="info-label">Major Genre:</span> {summary.major_genre}</p>
                        )}
                        {currentLocation && (
                        <p className="summary-info"><span className="info-label">Lives:</span> {currentLocation}</p>
                        )}
                        {homeLocation && (
                        <p className="summary-info"><span className="info-label">From:</span> {homeLocation}</p>
                        )}
                    </>
                    ) : (
                    <div className="authorName-container">
                        <p className="summary-info-center">of whom little is known.</p>
                    </div>
                    )}
                </div>
            </div>
            {(summary.magazine_links.length > 0 || summary.social_links.length > 0) && (
                <div className="profilishButtonRow">
                    <button onClick={() => handlePress('VIEW_LINKS_AND_SOCIALS')} className="profilishButtonInline">
                        <span className="profilishButtonText">Lives and writing on other platforms</span>
                    </button>
                </div>
            )}
            {summary.short_bio && (
                <div className="authorSummary-bio">
                    <p>{summary.short_bio}</p>
                </div>
            )}
            {topBooks &&topBooks.length > 0 && <RecentBooksProfile summary={summary} topBooks={topBooks} />}
            {summary.short_bio && <div className="profilePage-divider"/>}
        </div>
      </div>
    );
};

export default AuthorSummary;
