import React from 'react';
import './ReusableBoxContainer.css';

const ReusableBoxContainer = ({
  title,
  description,
  extraDescription = '',
  showButton = false,
  buttonText = '',
  boldButton = false,
  centerTitle = true,
  onButtonPress = () => {},
  showInput = false, // New optional prop for showing input field
  inputValue = '', // New prop for input value
  onInputChange = () => {}, // New prop for input change handler
  inputType = 'text', // New prop for input type (e.g., 'text', 'number')
  placeholder = '', // New prop for input placeholder
  inputSize = 'default', // New prop for controlling the input size
}) => {

  const inputClassName = inputSize === 'large' ? 'largeInput' : 'textInput';

  return (
    <div className="reusableBoxContainer">
      <div className="contentViewInBox">
        {title && (
          <p
            className={`boldInBox ${centerTitle ? '' : 'leftAlignedTitle'}`}
          >
            {title}
          </p>
        )}
        {showInput && inputSize !== 'large' && (
          <input
            type={inputType}
            value={inputValue}
            onChange={onInputChange}
            placeholder={placeholder}
            className={inputClassName}
          />
        )}
        {showInput && inputSize === 'large' && (
          <textarea
            value={inputValue}
            onChange={onInputChange}
            placeholder={placeholder}
            className={inputClassName}
          />
        )}
        {description && <p className="textInBox">{description}</p>}
        {extraDescription && <p className="textInBox">{`\n${extraDescription}`}</p>}
        {showButton && (
          <button className={`buttoninbox ${boldButton ? 'boldButton' : ''}`} onClick={onButtonPress}>
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default ReusableBoxContainer;
