import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../components/Header/Header';
import './Library.css';
import library from '../../assets/images/library.png'; // Make sure to import your feeding icon
import { apiInstance } from '../../utils/axiosInstance';// Adjust the import path as necessary
import failedbooks from '../../assets/images/book.png'; // Fallback image
import { fetchAuthSession } from 'aws-amplify/auth';
import bookImage from '../../assets/images/book.png'; 
import pieceImage from '../../assets/images/piece.png'; 
import { showGenericModal } from '../../store/modalSlice';

import {useNavigate} from 'react-router-dom';

function Library() {
  const dispatch = useDispatch();
  
  const navigate = useNavigate();
  const [readPosts, setReadPosts] = useState([]);
  const [likedPosts, setLikedPosts] = useState([]);

  const [interestBooks, setInterestBooks] = useState([]);
  const [readBooks, setReadBooks] = useState([]);
  const [ownedBooks, setOwnedBooks] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [showLoading, setShowLoading] = useState(true);
  const userId = useSelector((state) => state.user.userId);
  const [selectedTab, setSelectedTab] = useState('books'); // Default to books

  const MINIMUM_LOADING_TIME = 400; 

 
  useEffect(() => {
    const fetchData = async () => {
      const startTime = Date.now();
      setIsLoading(true);
      setShowLoading(true);

      try {
        // Get the current session to retrieve the JWT token
        const session = await fetchAuthSession();

        const jwtToken = session.tokens.idToken.toString();

        console.log(userId, 'userId');

        const [piecesResponse, booksResponse, likesResponse] = await Promise.all([
          apiInstance.get('/clicks/latestuserpieces', {
            params: { user_id: userId, limit: 10 },
            headers: { Authorization: `Bearer ${jwtToken}` }
          }),
          apiInstance.get('/clicks/user-books-summary', {
            params: { user_id: userId, limit: 10 },
            headers: { Authorization: `Bearer ${jwtToken}` }
          }),
          apiInstance.get('/clicks/latestpiecelikes', {
            params: { user_id: userId, limit: 10 },
            headers: { Authorization: `Bearer ${jwtToken}` }
          })
        ]);


        console.log('likesResponse.data', likesResponse.data)
        console.log('piecesResponse.data', piecesResponse.data)
        console.log('booksResponse.data', booksResponse.data)

        setReadPosts(piecesResponse.data || []);

        setLikedPosts(likesResponse.data || []);
        setInterestBooks(booksResponse.data.interest_books || []);
      setReadBooks(booksResponse.data.read_books || []);
      setOwnedBooks(booksResponse.data.owned_books || []);



        

        const endTime = Date.now();
        const loadTime = endTime - startTime;


        
        if (loadTime < MINIMUM_LOADING_TIME) {
          setTimeout(() => {
            setIsLoading(false);
            setTimeout(() => setShowLoading(false), 500); // Fade out effect
          }, MINIMUM_LOADING_TIME - loadTime);
        } else {
          setIsLoading(false);
          setTimeout(() => setShowLoading(false), 500); // Fade out effect
        }
      } catch (error) {
        console.error('Error fetching library data:', error);
        setIsLoading(false);
        setTimeout(() => setShowLoading(false), 500); // Fade out effect
      }
    };

    fetchData();
    console.log('read posts', readPosts)
  }, [userId]);


  const goToPreviousPiece = (hash) => {
    window.scrollTo(0, 0);

  
    navigate(`/piece/${hash}`, { 
      state: { 
        prevRoute: `/library`
      } 
    });
  };

  const generateHash = (length) => {
    return crypto.getRandomValues(new Uint8Array(length))
      .reduce((acc, i) => acc + ('0' + i.toString(36)).slice(-2), '')
      .slice(0, length);
  };
  
  const generateCustomTimestamp = () => {
    return Date.now().toString(36);
  };


  const updateLibraryStatus = (updatedBook, oldClicketyType) => {
    setReadBooks((prev) => prev.filter((b) => b.product_link_url !== updatedBook.product_link_url));
    setInterestBooks((prev) => prev.filter((b) => b.product_link_url !== updatedBook.product_link_url));
    setOwnedBooks((prev) => prev.filter((b) => b.product_link_url !== updatedBook.product_link_url));
  
    // Place the updated book at the start instead of the end
    if (updatedBook.status === "READ") {
      setReadBooks((prev) => [updatedBook, ...prev]);  
    } else if (updatedBook.status === "WANT") {
      setInterestBooks((prev) => [updatedBook, ...prev]); 
    } else if (updatedBook.status === "OWN") {
      setOwnedBooks((prev) => [updatedBook, ...prev]); 
    }
  
    console.log(`Updated book moved from ${oldClicketyType} to ${updatedBook.status}`);
  };
  
  

  const handleBookClick = (book, position, clicketyType) => {

    let derivedStatus = "";

    if (clicketyType === "READ-LIBRARY") {
      derivedStatus = "READ";
    } else if (clicketyType === "INTEREST-LIBRARY") {
      derivedStatus = "WANT";
    } else if (clicketyType === "OWNED-LIBRARY") {
      derivedStatus = "OWN";
    }

    dispatch(showGenericModal({
      modalType: 'BOOK_MODAL',
      modalProps: {
        book: {
          ...book,
          book_author_name: book.author, 
          book_title: book.title,
          book_image_url: book.image,
          user_status: derivedStatus 
        },
        prevRoute: 'library',
        position,  // Now uses actual position in list
        piece: '',
        author_info: { profile_hash: book.profile_hash, name: '' },
        pieceViewHash: 'LIBRARY',
        clicketyType, // Dynamically set based on the section
        onLibraryStatusUpdate: (updatedBook) => updateLibraryStatus(updatedBook, clicketyType) // NEW FUNCTION

      }
    }));
  };
  
  

  const renderReadPosts = () => {
    if (readPosts.length === 0) {
      return <p className="library__empty-message">No pieces read - go get-a-reading </p>;
    }

    return (
      <div className="library__post-feed">
        {readPosts.map((post, index) => (
          <div key={index} className="library__post-item" onClick={() => goToPreviousPiece(post.piece_hash)}>
            <img
              src={post.piece_image_jpeg || post.piece_image_webp || post.piece_image}
              alt={post.title}
              className="library__post-image"
            />
            <div className="library__post-details">
              <div className="library__post-title-container">
                <span className="library__post-title">
                  {post.piece_title}
                  {post.serial_part_number && (
                    <span className="library__post-part-number"> - Part {post.serial_part_number}</span>
                  )}
                </span>
              </div>
              <div className="library__post-author">{post.piece_writer_name}</div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderLikedPosts = () => {
    if (likedPosts.length === 0) {
      return <p className="library__empty-message">No pieces liked - go get-a-liking </p>;
    }

    return (
      <div className="library__post-feed">
        {likedPosts.map((post, index) => (
          <div key={index} className="library__post-item" onClick={() => goToPreviousPiece(post.piece_hash)}>
            <img
              src={post.piece_image_jpeg || post.piece_image_webp || post.piece_image}
              alt={post.title}
              className="library__post-image"
            />
            <div className="library__post-details">
              <div className="library__post-title-container">
                <span className="library__post-title">
                  {post.piece_title}
                  {post.serial_part_number && (
                    <span className="library__post-part-number"> - Part {post.serial_part_number}</span>
                  )}
                </span>
              </div>
              <div className="library__post-author">{post.piece_writer_name}</div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const RetryableImage = ({ src, alt, className, maxRetries = 15, interval = 1000, fallback }) => {
    const [retryCount, setRetryCount] = useState(0);
    const [imageSrc, setImageSrc] = useState(fallback); // Initially set to fallback image

    useEffect(() => {
      const img = new Image();
      
      img.onload = () => {
        setImageSrc(src); // Set to original source on successful load
      };
      
      img.onerror = () => {
        if (retryCount < maxRetries) {
          setTimeout(() => {
            setRetryCount(prevCount => prevCount + 1);
            img.src = `${src}?retry=${retryCount + 1}`; // Bypass cache with retry param
          }, interval);
        } else {
          setImageSrc(fallback); // Use fallback after max retries
        }
      };
      
      img.src = src; // Start loading the original image
      
      return () => {
        img.onload = null;
        img.onerror = null;
      };
    }, [src, retryCount, maxRetries, interval, fallback]);

    return <img src={imageSrc} alt={alt} className={className} />;
  };


  const renderBookFeed = (books, emptyMessage, clicketyType) => {
    if (books.length === 0) {
      return <p className="library__empty-message">{emptyMessage}</p>;
    }
  
    return (
      <div className="library__book-feed">
        {books.map((book, index) => (
          <div 
            key={index} 
            className="library__book-item" 
            onClick={() => handleBookClick(book, index, clicketyType)} // Pass clicketyType
          >
            <RetryableImage className="library__book-image" src={book.image} alt={book.title} fallback={failedbooks} />
            <div className="library__book-details">
              <div className="library__book-title">{book.title}</div>
              <div className="library__book-author">{book.author}</div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  

  return (
    <div className="library">
      <Header />
      {/* {showLoading ? (
        <div className={`loading-screen ${!isLoading ? 'fade-out' : ''}`}>
          <img src={library} alt="Loading" className="loading-icon" />
        </div>
      ) : ( */}
        <>
          <div className="library__toggle-container">
              {/* Books on the left */}
              <div 
                  className={`library__toggle-option ${selectedTab === 'books' ? 'active' : 'inactive'}`} 
                  onClick={() => setSelectedTab('books')}
              >
                  <img 
                      src={bookImage} 
                      alt="View Books" 
                      className="library__toggle-image"
                  />
                  <span className="library__toggle-text">Books</span>
              </div>

              {/* Pieces on the right */}
              <div 
                  className={`library__toggle-option ${selectedTab === 'pieces' ? 'active' : 'inactive'}`} 
                  onClick={() => setSelectedTab('pieces')}
              >
                  <img 
                      src={pieceImage} 
                      alt="View Pieces" 
                      className="library__toggle-image"
                  />
                  <span className="library__toggle-text">Pieces</span>
              </div>
          </div>


          <h2 className="library__title">
            {selectedTab === 'books' ? 'Your library of books' : 'Your library of pieces'}
          </h2>

          {/* Dynamic Content */}
          {selectedTab === 'books' ? (
            <div> 
              <div className="library__section">
                <h3 className="library__section-title">Books you've read</h3>
                {renderBookFeed(readBooks, "You haven't read a book? You should go get reading!", "READ-LIBRARY")}
              </div>
              <div className="library__section">
                <h3 className="library__section-title">Books you've shown interest in</h3>
                {renderBookFeed(interestBooks, "No books shown interest in - go find some!", "INTEREST-LIBRARY")}
              </div>
              <div className="library__section">
                <h3 className="library__section-title">Books you own (but haven't read)</h3>
                {renderBookFeed(ownedBooks, "You don't have a pile of books to read?", "OWNED-LIBRARY")}
              </div>
            </div>
          ) : (
            <>
              <div className="library__section">
                <h3 className="library__section-title">Pieces you've liked</h3>
                {renderLikedPosts()}
              </div>
              
              <div className="library__section">
                <h3 className="library__section-title">Pieces you've read</h3>
                {renderReadPosts()}
              </div>
            </>
          )}

        </>
      {/* )} */}
    </div>
  );
}

export default Library;