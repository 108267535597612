import React, { useState, useEffect } from 'react';
import './TagBookModalOne.css'; // Ensure CSS is structured similarly to EditMajorGenreModal.css
import { useSelector, useDispatch } from 'react-redux';
import { hideGenericModal, showGenericModal } from '../../store/modalSlice';
import { setChosenBook, setChosenFormat, setBooksOnSale, setFormatOptions} from '../../store/taggingSlice';


import { fetchAuthSession } from 'aws-amplify/auth';
import { apiInstance } from '../../utils/axiosInstance';
import { capitalizeFirstLetterOfEachWord } from '../../utils/capitalize';
import { setChosenAuthor, setRestrictedRetailer } from '../../store/taggingSlice';

const TagBookModal = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setSearching] = useState(false);
  const chosenBook = useSelector((state) => state.tagging.chosenBook);
  const chosenFormat = useSelector((state) => state.tagging.chosenFormat);
  const formatOptions = useSelector((state) => state.tagging.formatOptions);
  const chosenAuthor = useSelector((state) => state.tagging.chosenAuthor);
  const restrictedRetailer = useSelector((state) => state.tagging.restrictedRetailer);
  

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const getJwtToken = async () => {
    const session = await fetchAuthSession();
    const jwtToken = session.tokens.idToken.toString();
    return jwtToken;

  };



  const nextModal = (nextModalType, nextModalProps = {}) => {
    dispatch(hideGenericModal()); // Hide the current modal
    dispatch(showGenericModal({
      modalType: nextModalType,
      modalProps: nextModalProps
    })); // Immediately show the next modal
  };


  const handleSearch = async (event) => {
    event.preventDefault();
    setSearching(true);
  
    if (!searchTerm.trim()) {
      setTimeout(() => {
        setSearching(false);
      }, 400);
      return;
    }
  
    const jwtToken = await getJwtToken();
  
    try {
      const response = await apiInstance.get('/tag-search/books-authors-v3', {
        params: {
          query: searchTerm,
          search_type: 'books',
          author: chosenAuthor ? chosenAuthor.author : null,
          available_at_bn: restrictedRetailer === 'BN' ? 'true' : null,
          available_at_bam: restrictedRetailer === 'BAM' ? 'true' : null
        },
        headers: {
          'Authorization': `Bearer ${jwtToken}`,
        },
      });

      console.log('result', response.data.results);
  
      // Check if response.data.results exists and is an array
      if (Array.isArray(response.data.results)) {
        setSearchResults(response.data.results);
      } else {
        console.error('Unexpected response structure:', response.data);
        setSearchResults([]);
      }
    } catch (error) {
      console.error('Error during search:', error);
      setSearchResults([]);
    } finally {
      setSearching(false);
    }
  };

  const handleResetRetailer = () => {
    dispatch(setRestrictedRetailer(null));
  };

  const handleRestrictRetailer = () => {
    nextModal('RESTRICT_TO_RETAILER_MODAL');
  };


  const handleSelectBook = (book) => {
    dispatch(setChosenBook(book));
  };

  const handleClick = () => {
    if (chosenAuthor) {
      // Reset the chosen author
      dispatch(setChosenAuthor(null));
      // Optionally, you can trigger any additional actions needed after resetting
    } else {
      // Open the refine by author modal
      nextModal('REFINE_BY_AUTHOR_MODAL');
    }
  };









  return (
    <div className="modalBackground">
      <div className="modalesContaineres">
        <h2 className="modalsTitles">Tagging a book:</h2>
        <div className="forbreaks-hopes">
          <label className="sectionTitle">1. Search for a book:</label>
        </div>
        <form onSubmit={handleSearch}>
            <input
                type="text"
                className="search-input"
                placeholder="Type to search for books..."
                value={searchTerm}
                onChange={handleInputChange}
            />
            <button type="submit" className="infosButtonies-detailss" disabled={isSearching}>
                {isSearching ? 'Searching...' : 'Search'}
            </button>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button
                    type="button"
                    className="infosButton-half"
                    onClick={restrictedRetailer ? handleResetRetailer : handleRestrictRetailer}
                >
                    {restrictedRetailer ? 'Reset retailers' : 'Restrict to a retailer'}
                </button>
                <button
                    type="button"
                    className="infosButton-half"
                    onClick={handleClick}
                    
                >
                    {chosenAuthor ? 'Reset authors' : 'Refine to an author'}
                </button>
            </div>
        </form>
        <div className="tags-dividers"/>
        <div className="table-container">
            <table className="results-table">
                <thead>
                    <tr>
                        <th className="header-cell">Title</th>
                        <th className="header-cell">Author</th>
                    </tr>
                </thead>
            </table>
            <div className="scrollable-container">
                <table className="results-table">
                  <tbody>
                     {searchResults.map((book, index) => {
                        const capitalizedTitle = (book.title || book.raw_title) 
                            ? capitalizeFirstLetterOfEachWord(book.title || book.raw_title) 
                            : '';
                        
                        const capitalizedAuthor = book.author 
                            ? capitalizeFirstLetterOfEachWord(book.author) 
                            : '';
                        
                        return (
                          <tr 
                            key={index} 
                            className={`result-item ${chosenBook === book ? 'selected-book' : ''}`} 
                            onClick={() => handleSelectBook(book)}
                          >
                            <td className="result-cell">{capitalizedTitle}</td>
                            <td className="result-cell">{capitalizedAuthor}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
            </div>
        </div>
        <button 
          className="infosButton-details-next" 
          onClick={() => {
            console.log(chosenBook); // Log the chosen book
            nextModal('TAG_BOOK_ONE_AND_HALF', { /* next modal props */ });
          }} 
          disabled={!chosenBook}
        >
          Next step &nbsp;&nbsp;&#8209;&#8209;&#8209;&gt;&nbsp;
        </button>
      </div>
    </div>
  );
};

export default TagBookModal;
