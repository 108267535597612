import React, { useState } from 'react';
import { fetchAuthSession, confirmSignUp, signIn, signOut, resendSignUpCode } from 'aws-amplify/auth';

import { useDispatch, useSelector } from 'react-redux';
import { checkAuthenticationStatus, setCurrentLoginPage, setPassEmail, setPassPassword } from '../../store/userSlice';
import './ConfirmSignUp.css'; // Make sure to create this CSS file
import {apiInstance} from '../../utils/axiosInstance'; // Import the API instance
import { resetApplication } from '../../store/applyWriterSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { setAuthChecked } from '../../store/userSlice';



const ConfirmSignUp = () => {
  const [confirmationCode, setConfirmationCode] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const email = useSelector((state) => state.user.passEmail);
  const passterassword = useSelector((state) => state.user.passPassword);

  const saveUserToRds = async (email, cognitoUserId) => {
    try {
      const { accessToken, idToken } = await fetchAuthSession();
      const session = await fetchAuthSession();
      const jwtToken = session.tokens.idToken.toString();
  
      // Create FormData object and append data
      let formData = new FormData();
      formData.append('user_id', cognitoUserId);
      formData.append('email', email);
  
      // Use axios instance to make the call, ensuring to send FormData
      const response = await apiInstance.post('/users/create', formData, {
        headers: {
          'Authorization': `Bearer ${jwtToken}`,
          // Content-Type may not be needed as axios and browsers set it correctly for FormData
          // 'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status === 201) {
        console.log('User saved successfully:', response.data);
      } else {
        console.error('Error saving user:', response.statusText);
      }

      if (location.pathname === '/login') {
        navigate('/');
      } 
    } catch (error) {
      console.error('An error occurred while saving the user to RDS:', error);
    } finally {
      setIsProcessing(false);
    }
  };
  

  const handleConfirmSignUp = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    try {
      await confirmSignUp({ username: email, confirmationCode });
      await signOut();
      dispatch(setAuthChecked(false));
      const { isSignedIn, nextStep } = await signIn({ username: email, password: passterassword });
      if (isSignedIn) {
        dispatch(setAuthChecked(false));
        dispatch(checkAuthenticationStatus());
        const { tokens } = await fetchAuthSession();
        const cognitoUserId = tokens.idToken.payload.sub;
        await saveUserToRds(email, cognitoUserId);
        dispatch(setPassEmail(''));
        dispatch(setPassPassword(''));
        dispatch(setCurrentLoginPage('login'));
        dispatch(resetApplication());
        alert('Account confirmed... nicely done');
      } else {
        console.log('Additional step required:', nextStep);
        // Handle additional steps if needed
      }
    } catch (error) {
      console.error('Error confirming sign up', error);
      alert('Error confirming sign up: ' + error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleResendCode = async () => {
    
    if (isProcessing) return;
    setIsProcessing(true);
    try {
      await resendSignUpCode(email);
      alert('Code Sent, A new confirmation code has been sent to your email.');
    } catch (error) {
      console.error('Error resending code', error);
      alert('Error resending code: ' + error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleLoginPageReturn = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    try {
      await signOut();
      dispatch(setAuthChecked(false));
      dispatch(setPassEmail(''));
      dispatch(setPassPassword(''));
      dispatch(setCurrentLoginPage('login'));
      
    } catch (error) {
      console.error('Error signing out', error);
      dispatch(setPassEmail(''));
      dispatch(setPassPassword(''));
      dispatch(setCurrentLoginPage('login'));
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="confirmationBox">
      <div className="inputContainer">
        <input
          className="input"
          type="text"
          onChange={(e) => setConfirmationCode(e.target.value)}
          value={confirmationCode}
          placeholder="Confirmation code - check your email"
        />
      </div>
      <button className={`confirmButton ${isProcessing ? 'processing' : ''}`}  onClick={handleConfirmSignUp} disabled={isProcessing}>
        Confirm
      </button>
      <div className="separator" />

      <div className="linkContainer">
        <button className={`linkTouch ${isProcessing ? 'processing' : ''}`} onClick={handleResendCode} disabled={isProcessing}>
          Email code again
        </button>

        <button className={`linkTouch ${isProcessing ? 'processing' : ''}`}  onClick={handleLoginPageReturn} disabled={isProcessing}>
          Return to Login
        </button>
      </div>
    </div>
  );
};

export default ConfirmSignUp;
