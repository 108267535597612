import React from 'react';
import { Link } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';
import { setAuthChecked } from '../../store/userSlice';
import { useDispatch} from "react-redux";
// import './EmployeeLandingPage.css';

const EmployeeLandingPage = () => {
  const dispatch = useDispatch();
  const handleSignOut = async () => {
    try {
      await signOut();
      dispatch(setAuthChecked(false));
      // Redirect to login page or update app state
      // You might want to use your Redux store or React Router here
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="employee-landing-container">
      <h1>Employee Dashboard</h1>
      <nav className="employee-nav">
        <Link to="/employee-dashboard/review-applications" className="nav-link">
          Review Applications
        </Link>
        <Link to="/employee-dashboard/email-tool" className="nav-link">
          Email Tool
        </Link>
      </nav>
      <button onClick={handleSignOut} className="logout-button">
        Log Out
      </button>
    </div>
  );
};

export default EmployeeLandingPage;