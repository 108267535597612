import { createSlice } from '@reduxjs/toolkit';

const initialState = {
 pieceData: null,
 viewHash: null
};

export const pieceViewFromFeedSlice = createSlice({
    name: 'pieceViewFromFeed',
    initialState,
    reducers: {
      setPieceData: (state, action) => ({
        pieceData: action.payload.pieceData,
        viewHash: action.payload.viewHash
      }),
      resetPieceData: state => {
        state.pieceData = null;
        state.viewHash = null;
      }
    }
   });
   
   export const { setPieceData, resetPieceData } = pieceViewFromFeedSlice.actions;
   
export default pieceViewFromFeedSlice.reducer;

