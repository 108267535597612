import React, { useState, useEffect } from 'react';
import './BookCard.css'; // Import your book card styles
import failedbooks from '../../assets/images/book.png';
import bandn from '../../assets/images/bandn.jpeg';
import bam from '../../assets/images/bam.jpeg';
import waterstones from '../../assets/images/Waterstones.png';

const BookCard = ({ book }) => {

  console.log('book', book);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  
  const capitalizeAuthorName = (name) => {
    return name.split(' ').map(capitalizeFirstLetter).join(' ');
  };
  
  const capitalizeFirstWordOfTitle = (title) => {
    const words = title.split(' ');
    words[0] = capitalizeFirstLetter(words[0]);
    return words.join(' ');
  };

  const RetryableImage = ({ src, alt, className, maxRetries = 10, interval = 1000 }) => {
    const [retryCount, setRetryCount] = useState(0);
    const [imageSrc, setImageSrc] = useState(failedbooks); // Initially set to fallback image
  
    useEffect(() => {
      const img = new Image();
      img.onload = () => {

        setImageSrc(src); // Only set to the original source on successful load
      };
      img.onerror = () => {
        if (retryCount < maxRetries) {
          setTimeout(() => {
            console.log(`Retrying image load: Attempt ${retryCount + 1}`);
            setRetryCount(retryCount + 1);
            img.src = `${src}?retry=${retryCount + 1}`; // Attempt to reload with retry query
          }, interval);
        } else {
          setImageSrc(failedbooks); // Keep the fallback image if max retries are exceeded
        }
      };
      img.src = `${src}?retry=${retryCount}`; // Start loading with retry count to bypass cache
      // Set the source to the fallback image every time the component attempts to load the original image
      setImageSrc(failedbooks);
  
      return () => {
        img.onload = null;
        img.onerror = null;
      };
    }, [src, retryCount, maxRetries, interval]);
  
    // Always render the img element with the current source
    return <img src={imageSrc} alt={alt} className={className} />;
  };

  const capitalizedTitle = capitalizeFirstWordOfTitle(book.book_title);
  const capitalizedAuthor = capitalizeAuthorName(book.book_author_name);

  console.log('booked', book);

  return (
    <div className="book-card">
      <RetryableImage
        className="bookie-image"
        src={book.book_image_url}
        alt={book.book_title}
        maxRetries={0} // Customize as needed
        interval={1000} // 1000 milliseconds
      />
      <div className="book-info">
        <h3>{capitalizedTitle}</h3>
        <p>By {capitalizedAuthor}</p>
        {book.retailer_id && (
          <p className="retailer-info">available from 
            {book.retailer_id === 2 && (
              <img 
                src={bandn} 
                alt="Barnes & Noble logo" 
                style={{ height: '20px', marginLeft: '5px', verticalAlign: 'middle' }}
              />
            )}
            {book.retailer_id === 1 && (
              <img 
                src={bam} 
                alt="BAM logo" 
                style={{ height: '40px', marginLeft: '5px', verticalAlign: 'middle' }}
              />
            )}
            {book.retailer_id === 3 && (
              <img 
                src={waterstones} 
                alt="Waterstones logo" 
                style={{ height: '25px', marginLeft: '5px', marginTop: '-4px', verticalAlign: 'middle' }}
              />
            )}            
            
          </p>
          
        )}
      </div>
    </div>
  );
};

export default BookCard;