// axiosInstance.js
import axios from 'axios';





export const apiInstance = axios.create({
  baseURL: 'https://api.shrtrstrz.xyz/development',
  timeout: 320000,
  withCredentials: true, 
});


